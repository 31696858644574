@charset "UTF-8";
/* ===GETTING COLORS FROM PALETTE=== */
/**
 * The function palette is used by the following functions
 * text-color(), border-color() and bg-color()
 */
/* ===BACKGROUND COLOR FUNCTION=== */
/* ===TEXT COLOR FUNCTION=== */
/* ===BORDER COLOR FUNCTION=== */
/* ===GETTING BUTTONS STYLE FROM MAP=== */
/* ===GETTING BREAKPOINT VALUE FROM MAP=== */
/* ===GETTING VALUE FROM GENERIC MAP=== */
/* ===GET FONT FAMILY NAME FROM NESTED MAP=== */
/* ===REM FUNCTION=== */
/**
 * Sassy functions to get the rem equivalent of a px value
 * preset-css-env will take care of fallback for older browsers
 * !NOTE: you only need to change the $rem-baseline value if your base size 
 * is different from 10px (use _var_typography.scss in case)
 * @param value is referred to px
 * Credits: Rowan Manning
 */
/* ===WEB FONTS MANAGEMENT=== */
/**
* Use this mixin to easely generate a @font-face rule.
* It uses the font-path property provided by postcss rucksack
* to generate bulletproof src sets in @font-face
*/
/**
 * @param $fonts-map
 * Generating @font-face rule from the given $fonts map
 * You can just update the font map to add a new font-face rule
 */
/**
 * Sets the font for this weight.
 * @param $family the font family to set.
 * @param $weight one of 'bolder', 'heavy', 'bold', 'semibold', 'normal' (default),
 * 'light', 'lighter' or 'thin'
 */
/**
 * Sets the secondary fonts for this weight.
 * @param $weight one of "light", "normal" (default), "bold" or "bolder" (if provided by font).
 * Take care not to use this mixin to apply the primary font,
 * whenever it is already declared for the body element and inherited from it.
 */
/**
 * Applies the font smoothing
 * Used for better cross browser support
 * Ref: https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
/**
 * Absolute  positioning an element
 */
/* ===PALETTE SETUP=== */
/**
 * Replace the following colors with the one you actually need for your project.
 * These ones below are used just for sample scope and were taken from http://bada55.io/
 */
/**
 * in order to use one the colors included in the palette map,
 * you can simply use the functions text-color(key), bg-color(key), border-color(key)
 * ie: text-color(primary)
 */
/* ===BUTTONS COLORS SETUP=== */
/**
 * The $buttons map is used to style buttons
 * Please consider that the key used is the one used for classes name as well
 * ie: .button-primary, .button-secondary
 */
/**
 * Ref: https://www.smashingmagazine.com/2014/06/sassy-z-index-management-for-complex-layouts/
 * In order to maintain the stacking order of the elements,
 * we can set up a Sass list which represents the order in which we want our elements to appear,
 * from lowest to highest, like shown below.
 * (Note that if we want to add an element to the existing stacking order,
 * we just have to update the list with our new element)
 * In order to retrieve the z-index value to assign to each element,
 * you can use the index() sass function, for i.e. .modal {z-index: index($elements, modal)}
 */
/**
 * To make to solution scalable across the website, you can add multiple lists, related to specific contexts.
 * Remember that CSS spec informs us that
 * “stacking contexts are generated by any positioned element
 * (including relatively positioned elements) having a computed value of ‘z-index’ other than ‘auto’”,
 * so stick to this spec if you want to create a new stacking context.
 */
/* Replace with the actual fallback font */
/* Replace with the actual primary font */
/* Replace with the actual secondary font. Remove if not required */
/**
 * Include all the font properties in the following map
 * They will be used in the font-face function,
 * automatically generated by the @mixin font-face-generator
 */
/**
 * ACCESSIBLE FONT-SIZE-SETUP:
 * All font-sizes should scale accordingly if the user changes the default size settings
 * (medium size - corresponding to 16px in all modern browsers).
 * In order to make the font-size accessible and scalable according to the root element size,
 * we set the root HTML font-size as a percentage and we use rem unit then.
 * A typical method is to set the HTML font-size to 62.5%. That’s because 62.5% of 16px (typical default browser font-size) is 10px.
 * That would still make 1.6rem = 16px.
 * !NOTE: You only need to change the value of $font-size-rem-base, replacing it with the base font size required for your project if different from 14px, as supposed here
 * Ref: https://snook.ca/archives/html_and_css/font-size-with-rem
 */
/* used by rem() function to convert px to rem, according to browser setting */
/* ===BASELINE=== */
/**
 * List of modular scale names and values
 * Ref: http://www.modularscale.com/
 */
/* Leading is the measurement of space from baseline to baseline in a body of text */
/*  ===GRID GOLBAL SETTINGS=== */
/**
 * W3C Custom Media Queries Level 4 allow using the same media query in multiple places,
 * without the need to duplicate values across a stylesheet, and promote DRYer code.
 * Please, use the @custom-media rule to define a new custom media query.
 * Ref: https://drafts.csswg.org/mediaqueries-5/#custom-mq
 */
@custom-media --xs-only (width < 0);
@custom-media --xs-min (width >= 0);
@custom-media --sm-only (0 <= width < 768px);
@custom-media --sm-max (width < 768px);
@custom-media --sm-min (width >= 768px);
@custom-media --md-only (768px <= width < 1024px);
@custom-media --md-max (width < 1024px);
@custom-media --md-min (width >= 1024px);
@custom-media --lg-only (1024px <= width < 1280px);
@custom-media --lg-max (width < 1280px);
@custom-media --lg-min (width >= 1280px);
/**
 * Foundation for Sites by ZURB
 * Version 6.5.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.grid-container {
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  @media print, screen and (min-width: 48em) {
    .grid-container {
      padding-right: 1.5rem;
      padding-left: 1.5rem; } }
  .grid-container.fluid {
    padding-right: 1rem;
    padding-left: 1rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    @media print, screen and (min-width: 48em) {
      .grid-container.fluid {
        padding-right: 1.5rem;
        padding-left: 1.5rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex: 0 0 auto; }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex: 0 0 auto; }
  .grid-x > .medium-shrink {
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex: 0 0 auto; }
  .grid-x > .large-shrink {
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -1rem;
  margin-right: -1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x {
      margin-left: -1.5rem;
      margin-right: -1.5rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .cell {
      width: calc(100% - 3rem);
      margin-left: 1.5rem;
      margin-right: 1.5rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 2rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 2rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 2rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 2rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 2rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 2rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 2rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 2rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 2rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 2rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 2rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 2rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 3rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 3rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 3rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 3rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 3rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 3rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 3rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 3rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 3rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 3rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 3rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 3rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 3rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 3rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 3rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 3rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 3rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 3rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 3rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 3rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 3rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 3rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 3rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 3rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 3rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 3rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 3rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 3rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 3rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 3rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 3rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 3rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 3rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 3rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 3rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 3rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -1rem;
  margin-left: -1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -1.5rem;
      margin-left: -1.5rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -1rem;
  margin-left: -1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -1.5rem;
      margin-left: -1.5rem; } }

.grid-padding-x > .cell {
  padding-right: 1rem;
  padding-left: 1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-x > .cell {
      padding-right: 1.5rem;
      padding-left: 1.5rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 48em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 2rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 2rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 2rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 2rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 2rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 2rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 2rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 2rem); }

@media print, screen and (min-width: 48em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 3rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 3rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 3rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 3rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 3rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 3rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 3rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 3rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 3rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 3rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 3rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 3rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 3rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 3rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 3rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 3rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 3rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 3rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 3rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 3rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 3rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 3rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 3rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 3rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 48em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 1rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 1rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 1rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 1rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 1rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 1rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 1rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 1rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 1rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 1rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 1rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 1rem); }

@media print, screen and (min-width: 48em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1.5rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 1.5rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 1.5rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1.5rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 1.5rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 1.5rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1.5rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 1.5rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 1.5rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1.5rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 1.5rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 1.5rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 1.5rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 1.5rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 1.5rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 1.5rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 1.5rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 1.5rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 1.5rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 1.5rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 1.5rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 1.5rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 1.5rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 1.5rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto;
    max-width: none; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 48em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex: 0 0 auto; }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 48em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex: 0 0 auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex: 0 0 auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -1rem;
  margin-bottom: -1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -1.5rem;
      margin-bottom: -1.5rem; } }

.grid-padding-y > .cell {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-y > .cell {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; } }

.grid-margin-y {
  margin-top: -1rem;
  margin-bottom: -1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y {
      margin-top: -1.5rem;
      margin-bottom: -1.5rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 2rem);
    margin-top: 1rem;
    margin-bottom: 1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .cell {
      height: calc(100% - 3rem);
      margin-top: 1.5rem;
      margin-bottom: 1.5rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 2rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 2rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 2rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 2rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 2rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 2rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 2rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 2rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 2rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 2rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 2rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 2rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 3rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 3rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 3rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 3rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 3rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 3rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 3rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 3rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 3rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 3rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 3rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 3rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 3rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 3rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 3rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 3rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 3rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 3rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 3rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 3rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 3rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 3rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 3rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 3rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 3rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 3rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 3rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 3rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 3rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 3rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 3rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 3rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 3rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 3rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 3rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 3rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 48em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 48em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 48em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -1rem;
  margin-bottom: -1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y {
      margin-top: -1.5rem;
      margin-bottom: -1.5rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 2rem);
    margin-top: 1rem;
    margin-bottom: 1rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .cell {
      height: calc(100% - 3rem);
      margin-top: 1.5rem;
      margin-bottom: 1.5rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 2rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 2rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 2rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 2rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 2rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 2rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 2rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 2rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 2rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 2rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 2rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 2rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 3rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 3rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 3rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 3rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 3rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 3rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 3rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 3rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 3rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 3rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 3rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 3rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 3rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 3rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 3rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 3rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 3rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 3rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 3rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 3rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 3rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 3rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 3rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 3rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 3rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 3rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 3rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 3rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 3rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 3rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 3rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 3rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 3rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 3rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 3rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 3rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 2rem); }
  @media print, screen and (min-width: 48em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 3rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 3rem); } }

@media print, screen and (min-width: 48em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 3rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 3rem); } }

.align-left {
  justify-content: flex-start; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-left.vertical.menu > li > a {
  justify-content: flex-start; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 48em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 48em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 47.99875em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 48em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 47.99875em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.99875em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 47.99875em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.99875em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 79.99875em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.99875em), screen and (min-width: 80em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

/* Import third-party style here */
/* ===NORMALIZE STYLE=== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

@font-face {
  font-family: 'Josefin Sans';
  src: url(fonts/JosefinSans-Bold.woff2) format("woff2"), url(fonts/JosefinSans-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Josefin Sans';
  src: url(fonts/JosefinSans-Regular.woff2) format("woff2"), url(fonts/JosefinSans-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* Supported by firefox and Chrome only. Use a fallback for unsupported browsers, if you need. */
  font-size: 62.5%; }
  html *,
  html *:before,
  html *:after {
    /**
     * Use inheritance here in order to make easier
     * to change the box-sizing in plugins or other components that leverage other behavior.
     * Credits: Jonathan Neal http://blog.teamtreehouse.com/box-sizing-secret-simple-css-layouts#comment-50223
     */
    box-sizing: inherit; }
  html.has-v-grid {
    /**
     * !TODO: check $leading and $leading-base value
     */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(102, 51, 153, 0)  2.327rem, rebeccapurple  2.427rem);
    background-size: 100%  2.427rem;
    background-repeat: repeat-y; }

body {
  font-family: Montserrat;
  font-weight: 400;
  font-size:  1.5rem;
  line-height: 1.618;
  text-rendering: optimizeLegibility;
  background-color: var(--body-bg); }

main {
  color: var(--main-color); }

img {
  max-width: 100%; }

a {
  text-decoration: none;
  color: inherit; }

/*
.cell {
  background: salmon;
  &:nth-child(n+2) {
    background: #57a957;
  }
}

*/
/*******************************************
 GENERAL 
******************************************/
body {
  font-family: 'Josefin Sans', sans-serif;
  color: #A69C7B; }

.clearfix::after {
  content: " ";
  display: table;
  clear: both; }

/*******************************************
HEADER
******************************************/
.main-header {
  position: relative;
  background-color: #F3EACC;
  padding: 5px;
  text-align: center; }

.top-logo {
  width: 40px; }

.freccia {
  width: 25px; }

#freccia {
  position: absolute;
  top: 25%;
  left: 1%; }

/*******************************************
SEC HEADER
******************************************/
.secondary-header {
  margin-bottom: 30px;
  text-align: center; }

h1 {
  color: #A69C7B;
  font-size: 1.3em;
  font-weight: normal; }

.secondary-header p {
  margin-top: 30px; }

.darker {
  font-weight: 600; }

/*******************************************
NAV
******************************************/
nav {
  text-align: center;
  padding: 5px 0 5px 0; }

nav a {
  color: #A69C7B;
  font-size: 1.3em;
  margin: 0 7px;
  transition: all 0.07s; }

nav ul {
  list-style: none;
  margin: 5px 0;
  padding: 0; }

nav li {
  display: inline-block; }

nav a:hover {
  border-bottom: 2px #A69C7B solid; }

nav a.selected {
  border-bottom: 2px #A69C7B solid; }

/*******************************************
SECTION
******************************************/
.wrapper {
  max-width: 760px;
  margin: 0 auto;
  padding: 40px 5% 40px 5%; }

#gallery {
  list-style: none;
  margin: 0;
  padding: 0; }

img {
  max-width: 100%; }

.projects li {
  transition: all 0.1s;
  text-align: center;
  margin: 0 0 50px 0; }

.projects li img {
  transition: all 0.1s; }

.projects li img:hover {
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2); }

.projects li a p {
  font-size: 1em;
  margin: 10px 0 0 0; }

.wrapper-2 {
  max-width: 1060px;
  margin: 0 auto;
  padding: 50px 5% 40px 5%;
  min-height: calc(100vh - 330.1875px); }

.projects h2 {
  font-size: 1.3em;
  font-weight: normal; }

.italic {
  font-style: italic; }

/*******************************************
FOOTER
******************************************/
.main-footer {
  text-align: center;
  clear: both;
  padding: 5px;
  background-color: #F7F0DE; }

footer p {
  font-size: 0.85em; }

.icona-social {
  width: 30px;
  height: 30px;
  margin: 0 5px; }

/*******************************************
BREAKING
******************************************/
#breaking {
  background: url(images/breaking.webp) no-repeat center/cover;
  min-height: 230px; }

.scritta-bottom1 {
  text-align: center;
  padding-left: 27%;
  padding-top: 115px; }

.scritta-bottom2 {
  text-align: center;
  padding-left: 35%; }

#breaking p {
  font-size: 2em;
  font-weight: 400;
  margin: 0; }

/*******************************************
ABOUT
******************************************/
#fire {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url(images/fire.webp) no-repeat center/cover;
  min-height: 550px;
  margin-top: -35px; }

.about h2 {
  font-size: 1.3em;
  font-weight: 400; }

.about {
  max-width: 500px;
  background-color: #F3EACC;
  padding: 20px;
  text-align: center;
  margin: 20px auto;
  border-radius: 15px; }

.icons img {
  width: 20px; }

#twit {
  width: 25px; }

.icons {
  text-align: center; }

.icons li {
  display: inline-block;
  padding: 10px; }

ul {
  padding: 0; }

.social {
  padding-top: 0px; }

/*******************************************
PROGETTI
******************************************/
.link {
  font-weight: 600; }

/*******************************************
LIGHTBOX
******************************************/
.illustrations li {
  margin: 0; }

.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms; }

/* ===GRID SETUP=== */
/**
 * l_pillars is the main grid file, you don't need to change anything here,
 * unless you need to add customized grid rules for specific breakpoints.
 * Otherwise, you can only customize the grid variables 
 * that you can find into the _var_grid.scss abstract file
 */
.button-primary {
  background-color: #ffffff;
  border-color: #c0ffee;
  color: #000000; }
  .button-primary:hover, .button-primary:focus:hover {
    background-color: #bada55;
    border-color: #c0ffee;
    color: #663399; }
  .button-primary:active, .button-primary:active:focus, .button-primary:focus {
    background-color: #b000b5;
    border-color: #000000;
    color: #663399; }

.button-secondary {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #ffffff; }
  .button-secondary:hover, .button-secondary:focus:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #ffffff; }
  .button-secondary:active, .button-secondary:active:focus, .button-secondary:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #ffffff; }

/* Preload images */
body:after {
  content: url(images/close.png) url(images/loading.gif) url(images/prev.png) url(images/next.png);
  display: none; }

body.lb-disable-scrolling {
  overflow: hidden; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-container {
  padding: 4px; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(images/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

/* ===WIDTH MEDIA QUERY=== */
/**
 * W3C Custom Media Queries Level 4 allow using the same media query in multiple places, 
 * without the need to duplicate values across a stylesheet, and promote DRYer code.
 * Please, use the @custom-media rule to define a new custom media query.
 * Ref: https://drafts.csswg.org/mediaqueries-5/#custom-mq
 */
@custom-media --xs-only (width < 0);
@custom-media --xs-min (width >= 0);
@custom-media --sm-only (0 <= width < 768px);
@custom-media --sm-max (width < 768px);
@custom-media --sm-min (width >= 768px);
@custom-media --md-only (768px <= width < 1024px);
@custom-media --md-max (width < 1024px);
@custom-media --md-min (width >= 1024px);
@custom-media --lg-only (1024px <= width < 1280px);
@custom-media --lg-max (width < 1280px);
@custom-media --lg-min (width >= 1280px);
/**
 * From now on, you will be switching to specific breakpoints ranges.
 * Please refers to abstracts/_grids.scss for the complete list of the breakpoints used.
 * If you need any other media queries, please add them here below.
*/
/* ===IMPORT *FROM-EXTRASMALL* MODULES STYLE=== */
/* ===IMPORT *FROM-SMALL* MODULES STYLE=== */
@media (--sm-min) {
  .imm-sx {
    float: left;
    margin: 0 0 50px 0;
    width: 50%; }
  .descr-dx {
    float: right;
    width: 50%;
    padding-left: 5%; }
  .projects h2 {
    margin-top: 0;
    font-size: 1.3em; }
  .illustrations li {
    float: left;
    width: 25%; }
  #first {
    float: left;
    width: 60%;
    text-align: right; }
  #rotate {
    width: 40%;
    float: right;
    text-align: left; } }

/* ===IMPORT *SMALL-ONLY* MODULES STYLE=== */
/* ===IMPORT *FROM-MEDIUM* MODULES STYLE=== */
/* ===IMPORT *MEDIUM-ONLY* MODULES STYLE=== */
/* ===IMPORT *FROM-LARGE* MODULES STYLE=== */
/* ===IMPORT *LARGE-ONLY* MODULES STYLE=== */
/* ===IMPORT *UNTIL-LARGE* MODULES STYLE=== */
/* ===IMPORT *UNTIL-MEDIUM* MODULES STYLE=== */
/* ===IMPORT *UNTIL-SMALL* MODULES STYLE=== */
/* ===IMPORT *ONLY-EXTRASMALL* MODULES STYLE=== */
/* ===OTHER MEDIA FEATURES=== */
/**
 * Progressively Enhanced CSS Future Media Queries
 * Detecting devices features through level 4 media features
 * TODO: move elsewhere, this is not the right place for feature queries
 */
@media (pointer: coarse) {
  .which-pointer::after {
    content: 'You have a coarse pointer, are you on a touchscreen device?'; } }

@media (pointer: fine) {
  .which-pointer::after {
    content: 'You have a fine pointer, are you using a mouse or trackpad?'; } }

@media (hover) {
  .can-i-hover::after {
    content: 'You look like you can hover.'; } }

@media (hover: none) {
  .can-i-hover::after {
    content: "I don't think you can hover."; } }

/* ===GETTING COLORS FROM PALETTE=== */
/**
 * The function palette is used by the following functions
 * text-color(), border-color() and bg-color()
 */
/* ===BACKGROUND COLOR FUNCTION=== */
/* ===TEXT COLOR FUNCTION=== */
/* ===BORDER COLOR FUNCTION=== */
/* ===GETTING BUTTONS STYLE FROM MAP=== */
/* ===GETTING BREAKPOINT VALUE FROM MAP=== */
/* ===GETTING VALUE FROM GENERIC MAP=== */
/* ===GET FONT FAMILY NAME FROM NESTED MAP=== */
/* ===REM FUNCTION=== */
/**
 * Sassy functions to get the rem equivalent of a px value
 * preset-css-env will take care of fallback for older browsers
 * !NOTE: you only need to change the $rem-baseline value if your base size 
 * is different from 10px (use _var_typography.scss in case)
 * @param value is referred to px
 * Credits: Rowan Manning
 */
/* ===WEB FONTS MANAGEMENT=== */
/**
* Use this mixin to easely generate a @font-face rule.
* It uses the font-path property provided by postcss rucksack
* to generate bulletproof src sets in @font-face
*/
/**
 * @param $fonts-map
 * Generating @font-face rule from the given $fonts map
 * You can just update the font map to add a new font-face rule
 */
/**
 * Sets the font for this weight.
 * @param $family the font family to set.
 * @param $weight one of 'bolder', 'heavy', 'bold', 'semibold', 'normal' (default),
 * 'light', 'lighter' or 'thin'
 */
/**
 * Sets the secondary fonts for this weight.
 * @param $weight one of "light", "normal" (default), "bold" or "bolder" (if provided by font).
 * Take care not to use this mixin to apply the primary font,
 * whenever it is already declared for the body element and inherited from it.
 */
/**
 * Applies the font smoothing
 * Used for better cross browser support
 * Ref: https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
/**
 * Absolute  positioning an element
 */
/* ===PALETTE SETUP=== */
/**
 * Replace the following colors with the one you actually need for your project.
 * These ones below are used just for sample scope and were taken from http://bada55.io/
 */
/**
 * in order to use one the colors included in the palette map,
 * you can simply use the functions text-color(key), bg-color(key), border-color(key)
 * ie: text-color(primary)
 */
/* ===BUTTONS COLORS SETUP=== */
/**
 * The $buttons map is used to style buttons
 * Please consider that the key used is the one used for classes name as well
 * ie: .button-primary, .button-secondary
 */
/**
 * Ref: https://www.smashingmagazine.com/2014/06/sassy-z-index-management-for-complex-layouts/
 * In order to maintain the stacking order of the elements,
 * we can set up a Sass list which represents the order in which we want our elements to appear,
 * from lowest to highest, like shown below.
 * (Note that if we want to add an element to the existing stacking order,
 * we just have to update the list with our new element)
 * In order to retrieve the z-index value to assign to each element,
 * you can use the index() sass function, for i.e. .modal {z-index: index($elements, modal)}
 */
/**
 * To make to solution scalable across the website, you can add multiple lists, related to specific contexts.
 * Remember that CSS spec informs us that
 * “stacking contexts are generated by any positioned element
 * (including relatively positioned elements) having a computed value of ‘z-index’ other than ‘auto’”,
 * so stick to this spec if you want to create a new stacking context.
 */
/* Replace with the actual fallback font */
/* Replace with the actual primary font */
/* Replace with the actual secondary font. Remove if not required */
/**
 * Include all the font properties in the following map
 * They will be used in the font-face function,
 * automatically generated by the @mixin font-face-generator
 */
/**
 * ACCESSIBLE FONT-SIZE-SETUP:
 * All font-sizes should scale accordingly if the user changes the default size settings
 * (medium size - corresponding to 16px in all modern browsers).
 * In order to make the font-size accessible and scalable according to the root element size,
 * we set the root HTML font-size as a percentage and we use rem unit then.
 * A typical method is to set the HTML font-size to 62.5%. That’s because 62.5% of 16px (typical default browser font-size) is 10px.
 * That would still make 1.6rem = 16px.
 * !NOTE: You only need to change the value of $font-size-rem-base, replacing it with the base font size required for your project if different from 14px, as supposed here
 * Ref: https://snook.ca/archives/html_and_css/font-size-with-rem
 */
/* used by rem() function to convert px to rem, according to browser setting */
/* ===BASELINE=== */
/**
 * List of modular scale names and values
 * Ref: http://www.modularscale.com/
 */
/* Leading is the measurement of space from baseline to baseline in a body of text */
/*  ===GRID GOLBAL SETTINGS=== */
/**
 * W3C Custom Media Queries Level 4 allow using the same media query in multiple places,
 * without the need to duplicate values across a stylesheet, and promote DRYer code.
 * Please, use the @custom-media rule to define a new custom media query.
 * Ref: https://drafts.csswg.org/mediaqueries-5/#custom-mq
 */
@custom-media --xs-only (width < 0);
@custom-media --xs-min (width >= 0);
@custom-media --sm-only (0 <= width < 768px);
@custom-media --sm-max (width < 768px);
@custom-media --sm-min (width >= 768px);
@custom-media --md-only (768px <= width < 1024px);
@custom-media --md-max (width < 1024px);
@custom-media --md-min (width >= 1024px);
@custom-media --lg-only (1024px <= width < 1280px);
@custom-media --lg-max (width < 1280px);
@custom-media --lg-min (width >= 1280px);
.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

@custom-media --test_media (width <= 50rem);
@media (--test_media) {
  .test_present-env-media {
    color: white; } }

/* Testing presetenv */
.test_preset-env {
  background: color(red opacity(-10%)); }

/* Testing presetenv pseudo selectors facilities */
.test_preset-env:matches(.test, .main) {
  color: white; }

/* Testing presetenv custom properties with fallback */
:root {
  --test_custom-prop: #efefef; }

.test_preset-env-custom-properties {
  color: var(--test_custom-prop); }

/* Testing postcss-assets */
.test_postcss-assets {
  background: resolve("1x1.png"); }

/* Testing rucksack */
@font-face {
  font-family: "PlayfairDisplay";
  font-path: "/fonts/PlayfairDisplay-Regular";
  font-weight: 400; }

@font-face {
  font-family: "PlayfairDisplay";
  font-path: "/fonts/PlayfairDisplay-Bold";
  font-weight: 700; }

.test_rucksack-font-regular {
  font-family: Montserrat; }

.test_rucksack-font-bold {
  font-family: Montserrat;
  font-weight: bold;
  clear: fix; }

.test_rucksack-autoprefixer {
  user-select: none; }

/* Testing font functions and mixins */
.test_font-family {
  font-family: Montserrat; }

.test_font {
  font-family: Montserrat;
  font-weight: 600; }

/* Testing rem() function with single or mutil values added as parameters */
.test_rem-size {
  margin:  1.6rem 2rem;
  font-size:  2.1rem; }

/* Testing PostCSS sprites */
@custom-media --xs-only (width < 0);
@custom-media --xs-min (width >= 0);
@custom-media --sm-only (0 <= width < 768px);
@custom-media --sm-max (width < 768px);
@custom-media --sm-min (width >= 768px);
@custom-media --md-only (768px <= width < 1024px);
@custom-media --md-max (width < 1024px);
@custom-media --md-min (width >= 1024px);
@custom-media --lg-only (1024px <= width < 1280px);
@custom-media --lg-max (width < 1280px);
@custom-media --lg-min (width >= 1280px);
@custom-media --xl-min (width >= 1440px);
/* Toolbar Vars */
.tool_bar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100px;
  background-color: #333c43;
  color: #d5dbdd;
  margin-top: 0;
  padding-left: 0;
  z-index: 1; }
  .tool_bar ul {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
    margin-top: 5px; }
  .tool_bar li {
    border-bottom: 1px solid #495660; }
    .tool_bar li:hover, .tool_bar li.is-clicked {
      background-color: #3e4951; }

.tool_bar__button {
  color: #d5dbdd;
  border: 0;
  background-color: transparent;
  font-weight: 300;
  font-size:  1rem;
  padding: 20px 0;
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase; }
  .tool_bar__button .fa {
    color: #b1cbbf;
    display: block;
    padding-bottom: 10px;
    font-size:  2rem; }
  .tool_bar__button span {
    font-size: inherit; }

.tool_layer {
  background: #f7f7f7;
  position: fixed;
  right: 100px;
  top: 0;
  padding: 35px;
  bottom: 0;
  width: 425px;
  height: 100%;
  overflow-y: scroll; }
  .tool_layer.is-closed {
    display: none; }
  .tool_layer.is-opened {
    display: block; }

.tool_title {
  margin: 5px 0 10px; }

.tool_subtitle {
  margin: 25px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #cccccc;
  font-size:  1.6rem;
  text-transform: uppercase;
  color: #8c8c8c; }

.tool_par {
  font-size:  1.4rem; }

/* ===COLOR BOXES=== */
.tool_boxes {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: 20px; }

.tool_box {
  display: inline-block;
  position: relative;
  border-radius: 1px;
  margin-left: 25px;
  margin-bottom: 15px;
  flex: 0 0 80px;
  padding-top: 10px; }
  .tool_box::before {
    content: '';
    width: 120px;
    height: 60px;
    display: block;
    margin: 0 auto; }
  .tool_box::after {
    font-size:  1.1rem;
    color: #737373;
    text-align: center;
    display: block;
    padding: 5px;
    text-transform: uppercase; }
  .tool_layer .tool_box {
    margin-left: 5px;
    margin-bottom: 5px;
    flex: 0 0 60px; }
    .tool_layer .tool_box::before {
      width: 50px;
      height: 50px; }

.tool_box-text--primary::before {
  background-color: #000000;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-text--primary::after {
  content: "primary: #000000"; }

.tool_box-text--secondary::before {
  background-color: #663399;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-text--secondary::after {
  content: "secondary: #663399"; }

.tool_box-text--tertiary::before {
  background-color: #c0ffee;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-text--tertiary::after {
  content: "tertiary: #c0ffee"; }

.tool_box-text--reverse::before {
  background-color: #ffffff;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-text--reverse::after {
  content: "reverse: #ffffff"; }

.tool_box-text--brand::before {
  background-color: #b000b5;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-text--brand::after {
  content: "brand: #b000b5"; }

.tool_box-text--success::before {
  background-color: #bada55;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-text--success::after {
  content: "success: #bada55"; }

.tool_box-text--warning::before {
  background-color: #fab135;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-text--warning::after {
  content: "warning: #fab135"; }

.tool_box-text--error::before {
  background-color: #e2071c;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-text--error::after {
  content: "error: #e2071c"; }

.tool_box-background--primary::before {
  background-color: #ffffff;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--primary::after {
  content: "primary: #ffffff"; }

.tool_box-background--secondary::before {
  background-color: #663399;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--secondary::after {
  content: "secondary: #663399"; }

.tool_box-background--tertiary::before {
  background-color: #c0ffee;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--tertiary::after {
  content: "tertiary: #c0ffee"; }

.tool_box-background--reverse::before {
  background-color: #ffffff;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--reverse::after {
  content: "reverse: #ffffff"; }

.tool_box-background--brand::before {
  background-color: #b000b5;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--brand::after {
  content: "brand: #b000b5"; }

.tool_box-background--success::before {
  background-color: #bada55;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--success::after {
  content: "success: #bada55"; }

.tool_box-background--warning::before {
  background-color: #fab135;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--warning::after {
  content: "warning: #fab135"; }

.tool_box-background--error::before {
  background-color: #e2071c;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--error::after {
  content: "error: #e2071c"; }

.tool_box-background--debug::before {
  background-color: #fab135;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-background--debug::after {
  content: "debug: #fab135"; }

.tool_box-border--primary::before {
  background-color: #000000;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-border--primary::after {
  content: "primary: #000000"; }

.tool_box-border--secondary::before {
  background-color: #663399;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-border--secondary::after {
  content: "secondary: #663399"; }

.tool_box-border--tertiary::before {
  background-color: #c0ffee;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-border--tertiary::after {
  content: "tertiary: #c0ffee"; }

.tool_box-border--reverse::before {
  background-color: #ffffff;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-border--reverse::after {
  content: "reverse: #ffffff"; }

.tool_box-border--brand::before {
  background-color: #b000b5;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-border--brand::after {
  content: "brand: #b000b5"; }

.tool_box-border--success::before {
  background-color: #bada55;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-border--success::after {
  content: "success: #bada55"; }

.tool_box-border--warning::before {
  background-color: #fab135;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-border--warning::after {
  content: "warning: #fab135"; }

.tool_box-border--error::before {
  background-color: #e2071c;
  box-shadow: 0 0 5px #aeb5bd;
  border-radius: 5px; }

.tool_box-border--error::after {
  content: "error: #e2071c"; }

.tool_h1-props::after {
  content: " font-size :  3.2rem  | "; }

.tool_h1-props::after {
  content: " font-size :  3.2rem  |  font-family : Montserrat  | "; }

.tool_h1-props::after {
  content: " font-size :  3.2rem  |  font-family : Montserrat  |  font-weight : 700  | "; }

.tool_h1-props::after {
  content: " font-size :  3.2rem  |  font-family : Montserrat  |  font-weight : 700  |  line-height :  3.8rem  | "; }

.tool_h1-props::after {
  content: " font-size :  3.2rem  |  font-family : Montserrat  |  font-weight : 700  |  line-height :  3.8rem  |  color : #000000  | "; }

.tool_h1-props::after {
  content: " font-size :  3.2rem  |  font-family : Montserrat  |  font-weight : 700  |  line-height :  3.8rem  |  color : #000000  |  margin :  1.6rem 0rem  | "; }

.tool_h2-props::after {
  content: " font-size :  2.2rem  | "; }

.tool_h2-props::after {
  content: " font-size :  2.2rem  |  font-family : Montserrat  | "; }

.tool_h2-props::after {
  content: " font-size :  2.2rem  |  font-family : Montserrat  |  font-weight : 600  | "; }

.tool_h2-props::after {
  content: " font-size :  2.2rem  |  font-family : Montserrat  |  font-weight : 600  |  line-height : normal  | "; }

.tool_h2-props::after {
  content: " font-size :  2.2rem  |  font-family : Montserrat  |  font-weight : 600  |  line-height : normal  |  color : #000000  | "; }

.tool_h2-props::after {
  content: " font-size :  2.2rem  |  font-family : Montserrat  |  font-weight : 600  |  line-height : normal  |  color : #000000  |  margin :  1.6rem 0rem  | "; }

.tool_h3-props::after {
  content: " font-size :  1.4rem  | "; }

.tool_h3-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  | "; }

.tool_h3-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 700  | "; }

.tool_h3-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 700  |  line-height : normal  | "; }

.tool_h3-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 700  |  line-height : normal  |  color : #000000  | "; }

.tool_h3-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 700  |  line-height : normal  |  color : #000000  |  margin :  1.6rem 0rem  | "; }

.tool_p-props::after {
  content: " font-size :  1.6rem  | "; }

.tool_p-props::after {
  content: " font-size :  1.6rem  |  font-family : Montserrat  | "; }

.tool_p-props::after {
  content: " font-size :  1.6rem  |  font-family : Montserrat  |  font-weight : 400  | "; }

.tool_p-props::after {
  content: " font-size :  1.6rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height :  2.6rem  | "; }

.tool_p-props::after {
  content: " font-size :  1.6rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height :  2.6rem  |  color : #000000  | "; }

.tool_p-props::after {
  content: " font-size :  1.6rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height :  2.6rem  |  color : #000000  |  margin :  0.8rem 0rem  | "; }

.tool_p-props::after {
  content: " font-size :  1.6rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height :  2.6rem  |  color : #000000  |  margin :  0.8rem 0rem  |  letter-spacing :  -0.023rem  | "; }

.tool_a-props::after {
  content: " font-size :  1.4rem  | "; }

.tool_a-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  | "; }

.tool_a-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 400  | "; }

.tool_a-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height : normal  | "; }

.tool_a-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height : normal  |  color : #000000  | "; }

.tool_a-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height : normal  |  color : #000000  |  text-decoration : underline  | "; }

.tool_small-props::after {
  content: " font-size :  1.4rem  | "; }

.tool_small-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  | "; }

.tool_small-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 400  | "; }

.tool_small-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height : normal  | "; }

.tool_small-props::after {
  content: " font-size :  1.4rem  |  font-family : Montserrat  |  font-weight : 400  |  line-height : normal  |  color : #000000  | "; }

.tool_section {
  margin: 45px 0;
  clear: fix; }

.tool_code {
  padding: 10px 0;
  color: #999999;
  border-bottom: 1px solid #bfbfbf;
  border-top: 1px solid #bfbfbf;
  font-size:  1.2rem;
  margin: 15px 0; }

.tool_icon {
  float: left;
  text-align: center;
  padding: 8px 12px;
  margin-bottom: 10px; }

.tool_icon-name {
  font-size:  1.2rem;
  color: #737373;
  padding: 0 5px;
  border-radius: 2px;
  display: block;
  background-color: #f2f2f2; }

.tool-mq {
  font-size: 11px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: black;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%; }
  .tool-mq div {
    opacity: 0; }
  .tool-mq .mq::after {
    display: inline;
    padding-left: 10px; }
  .tool-mq .mq.xs::after {
    content: "width < 768px"; }
  .tool-mq .mq.sm::after {
    content: "768px <= width < 1024px"; }
  .tool-mq .mq.md::after {
    content: "1024px <= width < 1280px"; }
  .tool-mq .mq.lg::after {
    content: "1280px <= width < 1440px"; }
  .tool-mq .mq.xl::after {
    content: "width >= 1440px"; }
  .tool-mq:hover {
    height: auto;
    width: auto;
    border-radius: 5px; }
    .tool-mq:hover div {
      transition: opacity ease-in-out 0.4s;
      padding: 5px;
      border-bottom: 1px solid transparent;
      opacity: 0.4; }
    @media (--xs-only) {
      .tool-mq:hover .xs {
        opacity: 1; } }
    @media (--sm-only) {
      .tool-mq:hover .sm {
        opacity: 1; } }
    @media (--md-only) {
      .tool-mq:hover .md {
        opacity: 1; } }
    @media (--lg-only) {
      .tool-mq:hover .lg {
        opacity: 1; } }
    @media (--xl-min) {
      .tool-mq:hover .xl {
        opacity: 1; } }
    .tool-mq:hover::before {
      opacity: 0; }
  .tool-mq::before {
    transition: opacity ease-in-out 0.2s;
    content: 'MQ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1; }

body.is-grid-shown .debug_container {
  display: block; }

body.debugger-enabled #dev_debugger {
  display: block; }

#dev_debugger {
  display: none; }

.debug_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none; }
  .debug_container .button {
    position: fixed;
    right: 5px;
    bottom: 5px; }

.debug_row {
  height: 100%; }

.debug_box {
  background: rgba(250, 128, 114, 0.25);
  height: 100%; }

/* ===PRISM.JS=== */
/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */
code[class*="language-"],
pre[class*="language-"] {
  color: #ccc;
  background: none;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto; }

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #2d2d2d; }

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal; }

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #999; }

.token.punctuation {
  color: #ccc; }

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
  color: #e2777a; }

.token.function-name {
  color: #6196cc; }

.token.boolean,
.token.number,
.token.function {
  color: #f08d49; }

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: #f8c555; }

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
  color: #cc99cd; }

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable {
  color: #7ec699; }

.token.operator,
.token.entity,
.token.url {
  color: #67cdcc; }

.token.important,
.token.bold {
  font-weight: bold; }

.token.italic {
  font-style: italic; }

.token.entity {
  cursor: help; }

.token.inserted {
  color: green; }

/* ===X-rayHTML=== */
.xrayhtml {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .3em;
  margin: 1.5em 0 2.5em 0;
  padding: 1em 1em 2em; }

.xrayhtml.xray-copy {
  position: relative; }

.xrayhtml .xraytitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  font: .75em sans-serif;
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  top: -2.166666667em;
  /* 26px */
  padding-left: .1em;
  padding-right: .1em;
  z-index: 3;
  margin: 0; }

.xrayhtml.method-flip:before {
  background-color: rgba(255, 255, 255, 0.6); }

.xrayhtml .source-panel {
  background: #f7f7f7;
  margin-top: 2em;
  tab-size: 2; }

.xrayhtml .source-panel pre {
  margin: 0;
  padding: 16px;
  border-radius: 0 0 .3em .3em; }

.xrayhtml .source-panel code {
  white-space: pre-wrap; }

.xrayhtml.method-flip .source-panel {
  margin-top: 0;
  border-radius: 0.3em; }

.xrayhtml.method-inline .source-panel {
  margin: 2em -1em -2em -1em !important;
  /* Prism override. */
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0 .3em .3em; }

/* Prism override. */
.xrayhtml .source-panel code.language-markup {
  white-space: pre-wrap !important; }

.xrayhtml.antipattern {
  border-color: #C9282D; }

.xrayhtml.antipattern .xraytitle {
  color: #d75e72;
  font-weight: 700; }

/* Flip Animation */
.xrayhtml.method-flip {
  padding: 0; }

.method-flip {
  -webkit-perspective: 2500px;
  -moz-perspective: 2500px;
  perspective: 2500px; }

.method-flip .source-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto; }

.method-flip .snippet {
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  min-height: 100%;
  background-color: #fff;
  padding: 1em;
  -webkit-transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-transform: rotateY(0deg);
  -moz-transform-style: preserve-3d;
  -moz-backface-visibility: hidden;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  -moz-transition: -moz-transform .4s ease-in-out; }

.method-flip.view-source .snippet {
  z-index: 1;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg); }

.method-flip .source-panel {
  -webkit-transform: rotateY(-180deg);
  -webkit-backface-visibility: hidden;
  -moz-transform: rotateY(-180deg);
  -moz-backface-visibility: hidden;
  -moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out; }

.method-flip.view-source .source-panel {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg); }

.method-flip.view-source .xraytitle {
  background-color: transparent;
  background-image: linear-gradient(to bottom, transparent, transparent 40%, #ffffff 40%, transparent); }

iframe.xray-iframe {
  border: 0;
  width: 100%; }
